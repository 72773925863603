// ==== default states === //
main, header.tap-ident, #tap-menu, footer {
	margin:0 auto;
	width:100vw;
}
img{
	max-width:100%;
	height:auto;
}
.a11y{
	position:absolute !important;
	height:1px;width:1px;
	overflow:hidden;
	clip:rect(1px 1px 1px 1px);
	clip:rect(1px, 1px, 1px, 1px);
}

// == header == //
header.tap-ident{
	position:relative;
}
.tap-ident--container{
	position:relative;
}
// === navigation === //

#tap-menu{
	position:relative;
	display: flex;
	flex-direction: row;
		width:100vw;
}
.tap-ident--global_nav{
	ul{
		list-style-type:none;
		display: flex;
		flex-direction: row;
		margin:1rem 0;
		li{
			padding:0 1rem 0 0;
			a{
				text-decoration: none;
			}
		}
		&:first-child{
		}
		&:nth-child(2){
			background-color:white;
		}
	}
}
.tap-contact--nav {
	ul{
		list-style-type:none;
	}
	.tap-contact--links{
		display:-ms-flexbox;
		display:flex;
		-ms-flex-wrap:wrap;
		flex-wrap:wrap;
		-ms-flex-pack:start;
		justify-content:
		flex-start;
		margin-bottom:1.5rem;
		li{
			padding:0 1rem 0 0;
		}
	}
}
// footer == //
footer{
	padding:2rem 0 4rem 0;
}
.tap-contact{
	div{
		margin:0 0 1rem 0;
	}
}
.tap-legal{
	li{
		display: block;
		margin:0 0 0.75rem 0;
		a{
			margin:0 0 0.75rem 0;
		}
	}
}
.tap-contact--links{
	margin:1rem 0;
	li{
		display: inline-block;
		margin:0 0.25rem 0;
		a{
			text-decoration:none;
		}
	}
}
.tap-legal{
	display:block;
	li{
		-ms-flex-direction:column;
		flex-direction:column;
	}
}

// === content === //
.tap-intro{
	margin:0 0 1rem 0;
}
.grid-container {
	display: inline-grid;
	gap: 1rem;
	grid-template-rows: auto;
	.grid-item{
		display: inline-grid;
		padding:0 0 0.5rem 0;
		align-content: start;
		.tap-grid--meta{
			padding:0 0.5rem;
			.tap-grid--meta-button{
				display:inline-grid;
				padding:0.25rem 0.5rem;
				border-radius:0.5rem;
				outline: none;
				text-decoration: none;
				overflow-x: hidden;
				overflow-y: hidden;
				margin:0.5rem 0;
				overflow-wrap: break-word;
				word-break: break-word;
				-webkit-hyphens: auto;
				white-space: normal;
				touch-action: manipulation;
			}
		}
	}
}





// ==== responsive rules ====== //

@media (min-width: 0px){
		main, header,#tap-menu, footer {
			max-width:80%;
		}
		header.tap-ident{
			padding:2rem 0 0 0;
		}
		.main{
			//margin:0.5rem 0;
		}
		.tap-post{
			max-width: 90%;
		}
}

// ========================== 321px + ==================
@media (min-width: 321px){
	header.tap-ident{
		padding:1rem 0 0 0;
	}
	main, header,#tap-menu, footer{
		max-width:85%;
	}
	.grid-container {
		.grid-item{
				margin:0 0 1rem 0;
			}
	}
	.grid-container--drop {
		grid-template-columns: none;
	}
	.grid-container--individual {
		grid-template-columns: none;
	}
	.grid-container--categories {
		grid-template-columns: none;
	}
	.grid-container--categories-index {
		grid-template-columns: none;
	}

}
// ========================== 480px + ==================
@media (min-width: 481px){
		main, header.tap-ident,#tap-menu, footer{
			max-width:90%;
		}
		.grid-container--drop {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		.grid-container--individual {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		.grid-container--categories {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
		.grid-container--categories-index {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
}
// ========================== 720px + ==================
@media (min-width: 720px){
		main, header.tap-ident, #tap-menu,footer{
			max-width:95%;
		}
		.main{
			margin:1rem 0;
		}
		.tap-ident{
			padding:0;
		}
		.grid-container {
			.grid-item{
				margin:0 0 1.5rem 0;
			}
		}
		.grid-container--drop {
			grid-template-columns: repeat(2, minmax(0, 1fr));
		}
		.grid-container--individual {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
		.grid-container--categories {
			grid-template-columns: repeat(3, minmax(0, 1fr));
		}
		.grid-container--categories-index {
			grid-template-columns: repeat(4, minmax(0, 1fr));
		}
	}

// ========================== 1024px + ==================
@media (min-width: 1024px){
	.main{
		margin:1rem 0 2rem 0;
	}
	.tap-post{
		max-width: 70%;
	}
	.grid-container--drop {
		grid-template-columns: repeat(2, minmax(0, 1fr));
	}
	.grid-container--individual {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
	.grid-container--categories {
		grid-template-columns: repeat(3, minmax(0, 1fr));
	}
	.grid-container--categories-index {
		grid-template-columns: repeat(4, minmax(0, 1fr));
	}
}

// ========================== 1200px + ==================
@media (min-width: 1200px){
		main, header.tap-ident, #tap-menu,footer {
			max-width:1024px;
		}
}
