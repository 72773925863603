// ==== resets === //
*, html {
  box-sizing: border-box;
}
*,*:before,*:after{box-sizing:inherit}
html,body{
	height:100%;
	margin:0;
	padding:0;
}
body, p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
  font-weight:normal;
}

ul, ol, dl, li{
  list-style-type: none;
}
/**
 * Basic styling
 */
body {
  font-family: 'Open Sans', sans-serif;
  line-height: 150%;
  direction: ltr;
  -webkit-font-variant-ligatures: no-common-ligatures;
  font-variant-ligatures: no-common-ligatures;
  -webkit-text-size-adjust: 100%;
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  box-sizing: border-box;
  }
// a11y hide text-transform
.skip
{
position:absolute;
left:-10000px;
top:auto;
width:1px;
height:1px;
overflow:hidden;
}

.skip:focus
{
position:static;
width:auto;
height:auto;
}
