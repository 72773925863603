.accordion {
  background-color: $header-color;
  cursor: pointer;
  padding: 1rem;
  width: 100%;
  border: none;
  outline: none;
  transition: 0.4s;
  margin:-0.5rem 0 2rem 0;
  &:after {
    content: '\02795'; /* Unicode character for "plus" sign (+) */
    //color: $text-color;
    float: right;
    margin-left: 5px;
  }
}
/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, /*.accordion:hover*/ {
  background-color: $text-color;
  color: white;
}
/*.accordion:hover {
  color:white!important;
  &:after{
    color:white!important;
  }
}*/
.active:after {
  content: "\2212";
  color: white;
  &:hover{
    color:white;
  }
}
.panel {
  padding: 0 0 1rem 0;
  display: none;
}



//=== masonry styling == //
.masonry-with-columns {
  columns: 4 200px;
  column-gap: 1rem;
  div {
    width: 150px;
    margin: 0 1rem 1rem 0;
    display: inline-block;
    width: 100%;
    text-align: left;
    font-family: system-ui;
    position:relative;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 350px;
    background-position: center top;
    background-color: $ident-color;
    h3{
      position:absolute;
      bottom:0;
      left:0;
      right:0;
      padding:0.5rem;
      margin:0;
      z-index: 2;
      background-color: transparentize($ident-color, .2);
    }
    a{text-decoration: underline;}
  }
  @for $i from 1 through 36 {
    div:nth-child(#{$i}) {
      $h: (random(430) + 100) + px;
      height: $h;
      line-height: $h;
    }
  }
}
.project-columns {
  display: flex;
  flex-direction:row;
  justify-content:space-between;
  flex-wrap: wrap;
  div{
    width:48%;
    margin:0 0 1rem 0;
  }
}
.project-columns-half{
  display: flex;
  flex-direction:row;
  justify-content:space-between;
  flex-wrap: wrap;
  div{
    width:100%;
    margin:0 0 1rem 0;
  }
}

.tap-header{
  background-image: url('../img/ident-black.png');
  background-size: contain;
  background-repeat: no-repeat;
  //text-indent: -10000px;
}





// === blog related === //

.post-list{
	li{
		margin:0 0 1rem 0;
	}
}

ul.index{
	li{
		margin:0 0 2rem 0;
	}
}
// ==== responsive rules ====== //

@media (min-width: 0px){
  .tap-header{
    margin:0 0 1rem 0;
    height:120px;
    padding:1rem;
  }
}
@media (min-width: 321px){
  .tap-header{
    margin:0 0 1rem 0;
    height:160px;
    padding:1rem;
  }
}
@media (min-width: 481px){
  .tap-header{
    margin:0 0 1rem 0;
    height:200px;
    padding:1rem;
  }
}
// ========================== 720px + ==================
@media (min-width: 720px){
  .tap-header{

  }
}
// ========================== 1024px + ==================
@media (min-width: 1024px){

}
