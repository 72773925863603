/* specific print rules */

@media print {
	#tap-description, #tap-menu, .tap-contact{
		display: none!important;
	}
	.tap-ident {
    	margin:0 0 2rem 0;
	}
	.tap-intro p, #tap-description p {
    	width: 100%;
	}
	.tap-project{
	  display: flex;
	  -ms-flex-direction:row;
	  flex-direction: row;
	  > div:first-child{
		  	padding:0 2rem 1rem 0;
			width:60%;
	  }
	  .image{
	  	width:40%;
	  }
	}
}
